












































































import ENV from '@/environment';
import axios from 'axios';
import { loader } from '../mixins/loader';
import { isMobile } from '../mixins/isMobile';
import { functions } from '../mixins/functions';

const MAX_OVERLAY_HEIGHT = 435;

export default {
  name: 'FAQ',
  mixins: [loader, isMobile, functions],
  data() {
    return {
      pageData: { page: 'FAQ', isHeaderHidden: true },
      categories: [],
      overlay: null,
      overlayContent: null,
      showArticle: false,
      article: {
        title: '',
        identifier: '',
        categoryTitle: '',
        body: '',
        isArticleContentTouchBottom: false,
      },
      openedSpoiler: null,
    };
  },
  deactivated() {
    this.isLoaded = false;
    this.removeLogoClass();
  },

  activated() {
    this.$store.commit('ui/loaderON');
    this.onActivated();
    this.addLogoClass();
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.loadArticles();
      },
    },
  },
  computed: {
    stylesForArticleContent() {
      if (this.isMobile) {
        return false;
      }

      return this.article.isArticleContentTouchBottom
        ? false
        : 'height: auto; border-radius: 2vh;';
    },
  },
  methods: {
    async onActivated() {
      await this.loadArticles();
    },
    loadArticles() {
      axios({
        method: 'get',
        url: `${ENV.APP_API_HOST}/api/faq?lang=${this.$root.$i18n.locale}`,
      })
        .then((data) => {
          this.categories = [...data.data.categories];
        })
        .catch(() => {})
        .finally(() => {
          this.pageLoaded();
        });
    },
    async getArticle(event, id) {
      event.preventDefault();
      this.showArticle = true;
      await this.$nextTick();

      this.overlay = document.body.appendChild(this.$refs.currentArticle);
      this.$refs.currentArticle.addEventListener('click', this.closeOverlay);
      this.overlayContent = this.$refs.currentArticleContent.attachShadow({
        mode: 'open',
      });

      axios({
        method: 'get',
        url: `${ENV.APP_API_HOST}/api/faq/${id}?lang=${this.$root.$i18n.locale}`,
      })
        .then((data) => {
          this.overlayContent.innerHTML = data.data.body;
          this.article.title = data.data.title;
          this.article.identifier = data.data.identifier;
          this.article.categoryTitle = data.data.categoryTitle;
          this.article.isArticleContentTouchBottom =
            this.$refs.overlayContent.clientHeight > MAX_OVERLAY_HEIGHT;
        })
        .catch(() => {});
    },
    closeOverlay() {
      this.$refs.currentArticle.removeEventListener('click', this.closeOverlay);
      this.overlay = null;
      this.overlayContent = null;
      this.showArticle = false;
      this.article = {
        title: '',
        identifier: '',
        categoryTitle: '',
        body: '',
        isArticleContentTouchBottom: false,
      };
    },
    addLogoClass() {
      const logo = document.querySelector('a.triangle');
      logo.classList.add('faq-logo');
    },
    removeLogoClass() {
      const logo = document.querySelector('a.triangle');
      logo.classList.remove('faq-logo');
    },
    onOpenSpolier(identifier) {
      if (this.openedSpoiler === identifier) {
        this.openedSpoiler = null;
        return;
      }
      this.openedSpoiler = identifier;
    },
  },
};
